import { template as template_6e3ed31a63ab4f0bb10888f3c18590e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6e3ed31a63ab4f0bb10888f3c18590e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
