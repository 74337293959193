import { template as template_31d834b677b24b77848d8b20e2ec3e54 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_31d834b677b24b77848d8b20e2ec3e54(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
